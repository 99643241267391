import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import BgImg from "../components/BgImg"

export default function Services({ data }) {
  const { html } = data.markdownRemark
  return (
    <Layout>
      <SEO title="Our Services" />
      <Container
        title="Our Services"
        style={{
          background: `#52575a`,
          color: `white`,
        }}
        whitetitle
      >
        <div
          style={{
            zIndex: 10,
            width: "100%",
            padding: "0 1rem",
            maxWidth: "500px",
          }}
          dangerouslySetInnerHTML={{ __html: html }}
        />
        <BgImg />
      </Container>
    </Layout>
  )
}

export const servicesQuery = graphql`
  query servicesQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "services" } }) {
      html
    }
  }
`
